@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
:root {
	--primary-color: #11b7d4;
	--primary-font: #f0f8ff;
	--secondary-font: #ff8800;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.App {
	min-height: 100vh;
	text-align: center;
	background-color: #111418;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: #f0f8ff;
	color: var(--primary-font);
}

.bg-filter {
	height: 100%;
	min-height: 100vh;
	width: 100%;
	background: rgb(0 0 0 / 40%);
}

.project-card {
	box-shadow: 0px 0px 20px -2px rgb(187 187 187 / 20%);
}

.project-card:hover {
	cursor: pointer;
	-webkit-transform: translateY(-5px) scale(1.02);
	        transform: translateY(-5px) scale(1.02);
	-webkit-transform-origin: center center;
	        transform-origin: center center;
}

.project-card img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.projects-wrapper {
	padding: 0 0 100px;
}

.cards-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 40px;
	gap: 40px;
	padding: 5%;
}

.cards-container h2 {
	margin-bottom: 5%;
	font-size: 70px;
	text-align: start;
	grid-column: span 3;
}

.cards-container h2 span {
	color: var(--primary-color);
}

@media screen and (min-width: 2000px) {
	.cards-container {
		grid-template-columns: repeat(4, 1fr);
	}

	.cards-container h2 {
		grid-column: span 4;
	}
}

@media screen and (max-width: 1200px) {
	.cards-container {
		grid-template-columns: repeat(2, 1fr);
	}

	.cards-container h2 {
		grid-column: span 2;
	}
}

@media screen and (max-width: 900px) {
	.cards-container {
		grid-template-columns: 1fr;
	}

	.cards-container h2 {
		grid-column: span 1;
	}
}

@media screen and (max-width: 764px) {
	.cards-container h2 {
		text-align: center;
		font-size: 50px;
	}
}

.navbar {
	width: 100vw;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	height: 100px;
	padding: 0 40px;
}

.navbar ul {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	grid-gap: 30px;
	gap: 30px;
}

.navbar li {
	list-style: none;
}

.nav-link {
	color: var(--primary-font);
	font-size: 18px;
	text-decoration: none;
}

.nav-link-active {
	color: var(--secondary-font);
}

.home-wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	-webkit-align-items: center;
	        align-items: center;
	grid-gap: 30px;
	gap: 30px;
	padding: 100px 0;
}

.about-container {
	grid-column: 2 / span 1;
	text-align: left;
}

.greeting {
	color: var(--primary-color);
	font-size: 70px;
}

.title {
	font-size: 36px;
}

.title span {
	color: var(--secondary-font);
}

.about {
	padding: 20px 0;
}

.about span {
	color: var(--secondary-font);
}

.about-container button {
	text-align: center;
	background-color: var(--secondary-font);
	color: #fff;
	font-weight: bold;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	padding: 15px 30px;
	border-radius: 18px;
	box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 52%);
}

.profile-img {
	text-align: left;
}

.profile-img img {
	width: 300px;
	max-width: 100%;
}

.skills-container {
	grid-column: 2 / span 1;
	display: grid;
	grid-gap: 15px;
	gap: 15px;
}

.skills-container h3 {
	font-size: 40px;
	text-align: start;
}

.skills-container button {
	float: left;
	border: 1px solid var(--primary-font);
	border-radius: 18px;
	padding: 10px 20px;
	background-color: transparent;
	color: var(--primary-font);
	font-size: 1rem;
}

.skill-row {
	margin: 15px 0;
	display: grid;
	grid-template-columns: 1fr 7fr;
	-webkit-align-items: center;
	        align-items: center;
}

.skill-row span {
	border-radius: 15px;
	background-color: #ff8800;
	height: 50%;
	-webkit-animation: load 2s 0s;
	        animation: load 2s 0s;
}

@-webkit-keyframes load {
	from {
		width: 0%;
	}
}

@keyframes load {
	from {
		width: 0%;
	}
}

.skill-active {
	display: block;
}

.skill-inactive {
	display: none;
}

@media screen and (max-width: 1200px) {
	.home-wrapper {
		grid-template-columns: repeat(2, 1fr);
		padding: 100px 5%;
	}

	.about-container,
	.skills-container {
		grid-column: span 1;
	}
}

@media screen and (max-width: 768px) {
	.home-wrapper {
		grid-template-columns: 1fr;
	}

	.about-container,
	.skills-container {
		-webkit-order: 2;
		        order: 2;
		text-align: center;
		padding: 0 10%;
	}

	.profile-img {
		-webkit-order: 1;
		        order: 1;
		text-align: center;
	}
}

.connect-wrapper {
	padding: 0 0 150px;
}

.social-title {
	font-size: 70px;
	color: var(--primary-color);
}

.social-title::after {
	content: "!";
	color: var(--secondary-font);
}

.social-links,
.form-title {
	padding: 20px 0;
	font-size: 30px;
}

.social-links::after,
.form-title::after {
	content: ".";
	color: var(--secondary-font);
}

.divider-title {
	padding: 20px 0;
	font-size: 30px;
}

.form-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.contact-form {
	width: 50%;
	display: grid;
	grid-gap: 30px;
	gap: 30px;
	justify-items: center;
}

.contact-form input,
.contact-form textarea {
	width: 50%;
	max-width: 500px;
	padding: 10px;
	border: none;
	border-radius: 18px;
}

.contact-form button {
	text-align: center;
	background-color: var(--primary-color);
	color: #fff;
	font-weight: bold;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	padding: 15px 30px;
	border-radius: 18px;
	box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 52%);
}

@media screen and (max-width: 1400px) {
	.contact-form input,
	.contact-form textarea {
		width: 100%;
	}
}

@media screen and (max-width: 500px) {
	.contact-form {
		width: 90%;
	}
}

