.connect-wrapper {
	padding: 0 0 150px;
}

.social-title {
	font-size: 70px;
	color: var(--primary-color);
}

.social-title::after {
	content: "!";
	color: var(--secondary-font);
}

.social-links,
.form-title {
	padding: 20px 0;
	font-size: 30px;
}

.social-links::after,
.form-title::after {
	content: ".";
	color: var(--secondary-font);
}

.divider-title {
	padding: 20px 0;
	font-size: 30px;
}

.form-container {
	display: flex;
	justify-content: center;
}

.contact-form {
	width: 50%;
	display: grid;
	gap: 30px;
	justify-items: center;
}

.contact-form input,
.contact-form textarea {
	width: 50%;
	max-width: 500px;
	padding: 10px;
	border: none;
	border-radius: 18px;
}

.contact-form button {
	text-align: center;
	background-color: var(--primary-color);
	color: #fff;
	font-weight: bold;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	padding: 15px 30px;
	border-radius: 18px;
	box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 52%);
}

@media screen and (max-width: 1400px) {
	.contact-form input,
	.contact-form textarea {
		width: 100%;
	}
}

@media screen and (max-width: 500px) {
	.contact-form {
		width: 90%;
	}
}
