.projects-wrapper {
	padding: 0 0 100px;
}

.cards-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 40px;
	padding: 5%;
}

.cards-container h2 {
	margin-bottom: 5%;
	font-size: 70px;
	text-align: start;
	grid-column: span 3;
}

.cards-container h2 span {
	color: var(--primary-color);
}

@media screen and (min-width: 2000px) {
	.cards-container {
		grid-template-columns: repeat(4, 1fr);
	}

	.cards-container h2 {
		grid-column: span 4;
	}
}

@media screen and (max-width: 1200px) {
	.cards-container {
		grid-template-columns: repeat(2, 1fr);
	}

	.cards-container h2 {
		grid-column: span 2;
	}
}

@media screen and (max-width: 900px) {
	.cards-container {
		grid-template-columns: 1fr;
	}

	.cards-container h2 {
		grid-column: span 1;
	}
}

@media screen and (max-width: 764px) {
	.cards-container h2 {
		text-align: center;
		font-size: 50px;
	}
}
