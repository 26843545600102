.project-card {
	box-shadow: 0px 0px 20px -2px rgb(187 187 187 / 20%);
}

.project-card:hover {
	cursor: pointer;
	transform: translateY(-5px) scale(1.02);
	transform-origin: center center;
}

.project-card img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
