.navbar {
	width: 100vw;
	display: flex;
	justify-content: flex-end;
	height: 100px;
	padding: 0 40px;
}

.navbar ul {
	display: flex;
	align-items: center;
	gap: 30px;
}

.navbar li {
	list-style: none;
}

.nav-link {
	color: var(--primary-font);
	font-size: 18px;
	text-decoration: none;
}

.nav-link-active {
	color: var(--secondary-font);
}
