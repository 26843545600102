.home-wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	gap: 30px;
	padding: 100px 0;
}

.about-container {
	grid-column: 2 / span 1;
	text-align: left;
}

.greeting {
	color: var(--primary-color);
	font-size: 70px;
}

.title {
	font-size: 36px;
}

.title span {
	color: var(--secondary-font);
}

.about {
	padding: 20px 0;
}

.about span {
	color: var(--secondary-font);
}

.about-container button {
	text-align: center;
	background-color: var(--secondary-font);
	color: #fff;
	font-weight: bold;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	padding: 15px 30px;
	border-radius: 18px;
	box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 52%);
}

.profile-img {
	text-align: left;
}

.profile-img img {
	width: 300px;
	max-width: 100%;
}

.skills-container {
	grid-column: 2 / span 1;
	display: grid;
	gap: 15px;
}

.skills-container h3 {
	font-size: 40px;
	text-align: start;
}

.skills-container button {
	float: left;
	border: 1px solid var(--primary-font);
	border-radius: 18px;
	padding: 10px 20px;
	background-color: transparent;
	color: var(--primary-font);
	font-size: 1rem;
}

.skill-row {
	margin: 15px 0;
	display: grid;
	grid-template-columns: 1fr 7fr;
	align-items: center;
}

.skill-row span {
	border-radius: 15px;
	background-color: #ff8800;
	height: 50%;
	animation: load 2s 0s;
}

@keyframes load {
	from {
		width: 0%;
	}
}

.skill-active {
	display: block;
}

.skill-inactive {
	display: none;
}

@media screen and (max-width: 1200px) {
	.home-wrapper {
		grid-template-columns: repeat(2, 1fr);
		padding: 100px 5%;
	}

	.about-container,
	.skills-container {
		grid-column: span 1;
	}
}

@media screen and (max-width: 768px) {
	.home-wrapper {
		grid-template-columns: 1fr;
	}

	.about-container,
	.skills-container {
		order: 2;
		text-align: center;
		padding: 0 10%;
	}

	.profile-img {
		order: 1;
		text-align: center;
	}
}
