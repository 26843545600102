@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

:root {
	--primary-color: #11b7d4;
	--primary-font: #f0f8ff;
	--secondary-font: #ff8800;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.App {
	min-height: 100vh;
	text-align: center;
	background-color: #111418;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: var(--primary-font);
}

.bg-filter {
	height: 100%;
	min-height: 100vh;
	width: 100%;
	background: rgb(0 0 0 / 40%);
}
